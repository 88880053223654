import React from 'react';

import styled from 'styled-components';

const ContactComponent = () => (
    <Div id="contact-callout" className="content__center">
        <div className="container">
            <H2>Please Call <a href="tel:+19053209050" className="link--yellow">905-320-9050</a> or <a href="mailto:julie.pupsnsuds@gmail.com" className="link--yellow">email</a> to schedule an appointment today!</H2>
        </div>
    </Div>
);

export default ContactComponent;

const H2 = styled.h2`
    margin: 2rem 1rem;

    @media only screen and (max-width: 480px) {
        margin: 1rem;
    }
`;

const Div = styled.div`
    padding: 1px;
`;