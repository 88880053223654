import React from 'react';

import styled from 'styled-components';
import bone from '../images/bone.svg';
import scissors from '../images/scissors.svg';
import shower from '../images/shower.svg';
import soap from '../images/soap.svg';

const About = () => (
    <Section id="about">
        <div className="container flex-row">
            <ContainerLeft className="content">
                <Header className="title-underline">About Pups N Suds</Header>
                <Par>
                    Thank you to everyone who voted Pups N Suds #1 dog groomer in the 2017 Burlington Reader's Choice Awards!
                </Par>
                <Par>
                    Pups N Suds is a small boutique dog grooming studio located in Puslinch, ON. We aim to give your loved pups our full attention while they are with us. To do so we only have one dog in the studio to work on at a time.
                </Par>
                <Par>
                    At Pups N Suds we believe that all dogs deserve to look and feel their best. Pups N Suds prides itself in pampering dogs and making them feel loved.
                </Par>
                <a href="/#location">
                    <Button className="button button--blank">Our Location &amp; Hours</Button>
                </a>
            </ContainerLeft>
            <ContainerRight className="about-icons">
                <IconsWrap className="images">
                    <IconSpanLeft><Icon src={scissors} alt="dog grooming scissors" /></IconSpanLeft>
                    <IconSpanLeft><Icon src={bone} alt="dog bone" /></IconSpanLeft>
                </IconsWrap>
                <IconsWrapSecond className="images">
                    <IconSpanRight><Icon src={shower} alt="dog grooming bath" /></IconSpanRight>
                    <IconSpanRight><Icon src={soap} alt="dog grooming soap" /></IconSpanRight>
                </IconsWrapSecond>
            </ContainerRight>
        </div>
    </Section>

);

export default About;

const Section = styled.section`
    background: #004f74;

    padding: 50px 0;
`;
const Header = styled.h2`
    color: #ff9;

    margin-bottom: 25px;
`;
const Par = styled.p`
    color: #d3d3d3;

    margin-bottom: 15px;

    &:first-of-type {
        font-size: 1.1rem;
    }
`;
const ContainerLeft = styled.div`
    width:  100%;

    @media screen and (min-width: 900px) {
        width:  calc(60% - 20px);  
    }
`;
const ContainerRight = styled.div`
    
    width:  100%;

    @media screen and (max-width: 899px) {
        display: flex;
        flex-direction: row;
        justify-content: center;        
    }

    @media screen and (min-width: 900px) {
        padding-top: 10px;
        width:  40%;  
    }
`;

const IconsWrap = styled.div`
    display:  flex;
    flex-direction:  row;
    justify-content: flex-end;    

    @media screen and (max-width: 899px) {
        order: 2;  
    }
    @media screen and (min-width: 900px) {
        height:  150px;  
    }
`;
const IconsWrapSecond = styled.div`
    display:  flex;
    flex-direction:  row;
    justify-content: flex-end;    

    @media screen and (max-width: 899px) {
        order: 1;  
    }
    @media screen and (min-width: 900px) {
        height:  150px;  
    }
`;

const IconSpanLeft = styled.span`
    margin: 30px 20px 0;
    width: 90px;

    @media screen and (max-width: 550px) {
        margin: 30px 15px 0;
        width: 50px;       
    }
    @media screen and (min-width: 900px) {
        width: 130px;
        margin-left: 80px;   
    }
    @media screen and (min-width: 1050px) {
        margin-left: 100px;   
    }
    @media screen and (min-width: 1200px) {
        margin-left: 115px;   
    }
`;

const IconSpanRight = styled.span`
    margin: 30px 20px 0;
    width: 90px;

    @media screen and (max-width: 550px) {
        margin: 30px 15px 0;
        width: 50px;       
    }
    @media screen and (min-width: 900px) {
        width: 130px;
        margin-right: 80px;   
    }
    @media screen and (min-width: 1050px) {
        margin-right: 100px;   
    }
    @media screen and (min-width: 1200px) {
        margin-right: 115px;   
    }
`;

const Icon = styled.img`
    height:  auto;
    max-height:  none;
    margin: 0;
    width:  100%;
`;

const Button = styled.button`
    background-color: rgba(0,0,0,0.2);
    color: white;

    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    font-size: 0.9em;
    font-weight: normal;
    margin-right: 0;

    &:hover {
        background: rgba(0,0,0,0.4);
    }
`;
