import React from 'react';
import styled from 'styled-components';
import dog from '../images/dog.jpg';

const Intro = () => (
    <Section id="intro">
        <Container className="container">
            <ImageContainer className="image">
                <Img src={dog} alt="Dog Groomer" />
            </ImageContainer>
            <div className="content">
                <H2>Welcome to Pups N Suds professional dog&nbsp;grooming.</H2>
                <P>A coat free of mats, burs, tangles and skin free of fleas and ticks means your dog will be comfortable and happy.</P>
                <P>Regular grooming keeps your pets coat smelling fresh and looking at its best.</P>
            </div>
        </Container>
    </Section>
);

export default Intro;

const Section = styled.section`
    padding: 50px 0;    

    @media screen and (max-width: 700px) {
        padding: 30px 0;   
    }
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    
    @media screen and (max-width: 600px) {
        flex-direction: column;   

        text-align: center;
    }
`;

const ImageContainer = styled.div`
    max-width: 40%;

    @media screen and (max-width: 600px) {        
        max-width: 300px;
        margin-bottom: 20px;
    }
`;
const Img = styled.img`
  margin: 0;
`;

const H2 = styled.h2`
    margin-bottom: 25px;
`;

const P = styled.p`
    margin-bottom: 15px;
`;