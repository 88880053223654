import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import '../scss/header.scss';
import '../scss/fonts.scss';
import '../scss/nav.scss';

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__copy">
      <h1 className="header__h1">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
    </div>

    <div className="header__callouts">
      <a href="mailto:julie.pupsnsuds@gmail.com" className="header__link--first">
        <button className="first">Book Appointment</button>
      </a>
      <Link to="/rates/#services">
        <button className="last">Our Services</button>
      </Link>
    </div>

    <Menu />
  </header>
);

export default Header;
