import React from 'react';
import Header from '../components/header';
import PawsBorder from '../components/PawsBorder';
import Intro from '../components/Intro';
import About from '../components/About';
import HoursMap from '../components/HoursMap';
import ContactCallout from '../components/ContactCallout';
import Layout from '../layouts/index';

const IndexPage = () => {
  return (
    <Layout>
      <Header siteTitle={'Pups N Suds Dog Grooming'} />
      <PawsBorder />
      <Intro />
      <PawsBorder />
      <About />
      <HoursMap />
      <ContactCallout />
    </Layout>
  );
}

export default IndexPage;
