import React, { Component } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends Component {
    render() {
        const mapStyles = 
            [{ width: '100%' }, { height: '100%' },{"featureType": "administrative","elementType": "labels.text.fill","stylers": [{"color": "#444444"}]},{"featureType": "landscape","elementType": "all","stylers": [{"color": "#f2f2f2"}]},{"featureType": "poi","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "road","elementType": "all","stylers": [{"saturation": -100},{"lightness": 45}]},{"featureType": "road.highway","elementType": "all","stylers": [{"visibility": "simplified"}]},{"featureType": "road.highway","elementType": "geometry.fill","stylers": [{"color": "#ffff99"}]},{"featureType": "road.arterial","elementType": "labels.icon","stylers": [{"visibility": "off"}]},{"featureType": "transit","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "water","elementType": "all","stylers": [{"color": "#46bcec"},{"visibility": "on"}]}]
        ;

        return (
            <Map
                google={this.props.google}
                initialCenter={{
                    lat: 43.47955444261719,
                    lng: -80.13932457694959
                }}
                zoom={13}
                styles={mapStyles}
            >
                <Marker
                    title={'Pups N Suds'}
                    position={{ lat: 43.47955444261719, lng: -80.13932457694959 }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyADMBa-G766lyfghofGBFAi9Zs-Kf_QvM0"
})(MapContainer)
// export default MapContainer;
