import React from 'react';
import styles from './PawsBorder.module.css';

const PawsBorder = () => {
    return (
        <div className={styles.div} ></div>
    );
}

export default PawsBorder;
