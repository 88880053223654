import React from 'react';

import GoogleApiWrapper from "./MapContainer";
import styled from 'styled-components';
import styles from './hoursMap.module.css';
import '../scss/map.scss';

const HoursMap = () => (
    <Section id="location">
        <Header className="title-underline">Location &amp; Hours</Header>
        <Container className="container flex-row">
            <LeftDiv className="map__container">
                <MapContainer>
                    <GoogleApiWrapper />
                </MapContainer>
                <a 
                    href="https://www.google.com/maps/dir//2+Dogwood+Rd,+Morriston,+ON+N0B+2C0/@43.4793832,-80.1418351,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x882b825588ef2771:0x5c1bc4a9ad239fcb!2m2!1d-80.1392602!2d43.4793832!3e0?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <h4 className="no-margin">Get Directions</h4>
                </a>
            </LeftDiv>
            <RightDiv className="hours__container">
                <Hours className="hours">
                    <h3 className="title-underline mb-2">Hours of Operation</h3>
                    <table className="hours__list">
                        <tbody className={styles.tbody}>
                            <tr className={styles.hours__item}>
                                <td>Monday:</td>
                                <td>Closed</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Tuesday:</td>
                                <td>Closed</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Wednesday:</td>
                                <td>9:00am - 6:00pm</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Thursday:</td>
                                <td>9:00am - 6:00pm</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Friday:</td>
                                <td>9:00am - 6:00pm</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Saturday:</td>
                                <td>9:00am - 6:00pm</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Sunday:</td>
                                <td>Closed</td>
                            </tr>
                            <tr className={styles.hours__item}>
                                <td>Holidays:</td>
                                <td>Closed</td>
                            </tr>
                        </tbody>
                    </table>
                </Hours>
                <Address className="address">
                    <h3 className="title-underline">Address</h3>
                    <h4>2 Dogwood Rd.</h4>
                    <h4>Puslinch, ON</h4>
                    <h4>N0B 2J0</h4>
                </Address>
            </RightDiv>
        </Container>
    </Section>
);

const Section = styled.section`
    padding: 40px 0;
`;
const MapContainer = styled.div`
    position: relative;
    height: 400px;

    @media screen and (max-width: 700px) {
        height: 300px;
    }
`;
const Header = styled.h2`
    color: #014e73;
    padding: 0 20px;

    @media screen and (min-width: 1200px) {
        margin: 20px auto;
        max-width: 1200px;
    }

    &:after {
        left: 20px !important;
    }
`;
const Container = styled.div`

`;
const LeftDiv = styled.div`
    flex: 2;
    margin-right: 20px;

    @media screen and (max-width: 900px) {
        width: 100%;
        margin-right: 0;
    }
`;
const RightDiv = styled.div`
    flex: 1;
    margin-left: 20px;

    @media screen and (max-width: 900px) {
        margin-left: 0;
        width: 100%;

        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
    }
`;

const Hours = styled.div`

    @media screen and (max-width: 900px) {
        width: 60%;
    }
    @media screen and (max-width: 700px) {
        width: 65%;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;

const Address = styled.div`
    @media screen and (max-width: 900px) {
        width: calc(40% - 20px);
    }
    @media screen and (max-width: 700px) {
        width: calc(35% - 20px);
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;

export default HoursMap;